.actions-background {
  background-color: rgba(0, 0, 0, 0.05);
}

a {
  color: rgba(90, 90, 90, 0.863);
  text-decoration: none;
}

a:hover {
  color: "blue";
  text-decoration: underline;
}
